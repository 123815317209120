<template>
  <Resize class="hello">
    <div class="all">
      <div class="header"></div>
      <div class="center">
        <div class="left">
          <div class="left_one">
            <span class="size_data">上一日电站等价发电时TOP5</span>
            <!-- <span class="size_data">电站状态</span>
            <span class="size_data" style="margin-left:30px">实时告警</span>
            <div class="powerStation">
              <div class="powerStation_one" id="electricityType"></div>
              <div class="powerStation_Two" id="alarmApparatus"></div>
            </div> -->
            <ul class="ul_sty">
              <li><span class="address_sty">下河东李盛河光伏电站</span><span class="strip"></span><span class="address_sty">4.82
                  kWh/kWp</span></li>
              <li><span class="address_sty">古城西关河畔光伏电站</span><span class="strip1"></span><span class="address_sty"
                  style="margin-left:10px">4.81 kWh/kWp</span></li>
              <li><span class="address_sty">黄升白家光伏电站</span><span class="strip2"></span><span class="address_sty"
                  style="margin-left:16px">4.57 kWh/kWp</span></li>
              <li><span class="address_sty">大高马坊农鑫光伏电站</span><span class="strip3"></span><span class="address_sty"
                  style="margin-left:26px">4.55 kWh/kWp</span></li>
              <li><span class="address_sty">黄升白家光伏电站</span><span class="strip4"></span><span class="address_sty"
                  style="margin-left:30px">4.52 kWh/kWp</span></li>
            </ul>

          </div>
          <div class="left_two">
            <span class="size_data">社会贡献</span>
            <div class="left_center_data">
              <div class="leftList leftList1">
                <div class="P_sty">
                  <p class="number_S">{{ energy.toFixed(2) }}<span class="font">吨</span></p>
                  <p class="label1">节约标准煤</p>
                </div>
              </div>
              <div class="leftList leftList2">
                <div class="P_sty">
                  <p class="number_S">{{ reduce.toFixed(2) }} <span class="font">吨</span></p>
                  <p class="label2">CO<sub style="font-size:12px">2</sub> 减排量</p>
                </div>
              </div>
              <div class="leftList leftList3">
                <div class="P_sty">
                  <p class="number_S">{{ plant.toFixed(0) }}<span class="font">棵</span></p>
                  <p class="label3">等效植树量</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="middle">
          <div class="middleData">
            <div class="middleList">
              <div>
                <img src="../assets/icon1.png" alt="">
              </div>
              <div>
                <p class="font_data">{{ power.toFixed(2) }}<span class="spanData">kw</span></p>
                <p class="power">当前功率</p>
              </div>
            </div>
            <div class="middleList">
              <div>
                <img src="../assets/icon2.png" alt="">
              </div>
              <div>
                <p class="font_data">{{ dynamo.toFixed(2) }} <span class="spanData"> 度</span></p>
                <p class="power">当日发电量</p>
              </div>
            </div>
            <div class="middleList">
              <div>
                <img src="../assets/icon3.png" alt="">
              </div>
              <div>
                <p class="font_data">{{ earnings.toFixed(2) }}<span class="spanData">元</span></p>
                <p class="power">当日收益</p>
              </div>
            </div>
            <div class="middleList">
              <div>
                <img src="../assets/icon4.png" alt="">
              </div>
              <div>
                <p class="font_data">{{ addUp.toFixed(2) }}<span class="spanData">万度</span></p>
                <p class="power">累计发电量</p>
              </div>
            </div>
          </div>
          <div class="mapImg">
            <div class="makData" v-for="item in location" :key="item.id" style="position:absolute"
              :style="{ left: item.left + 'px', top: item.top + 'px', background: item.color }" @click="gitSite(item)">
            </div>
          </div>
          <div class="imgSite" v-if="isShow">
            <div class="img_Sty" style="margin-left:7px">
              <img class="img_Sty" :src="imgListData" alt="">
            </div>
            <div class="site_size">{{ placeData }}</div>
          </div>
        </div>
        <div class="right">
          <div class="right_one">
            <span class="size_data">光伏项目收益支出分布图</span>
            <div id="myChart" :style="{ width: '526px', height: '240px', margin: '0 auto' }"></div>
          </div>
          <div class="right_two">
            <span class="size_data">村集体收入区间统计</span>
            <div id="histogram" :style="{ width: '526px', height: '240px', margin: '0 auto' }"></div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom_one">
          <span class="size_data">试点项目情况一览表</span>
          <div class="table_header">
            <div class="table_td" v-for="item in arrData" :key="item.id"> <span class="table_td_size">{{ item.name
            }}</span>
            </div>
          </div>
          <div class="tableData">
            <div class="keepOut">
              <div class="table_content" v-for="item in contentData" :key="item">
                <div class="table_td" v-for="list in item" :key="list.id"> <span class="table_td_size">{{ list.name
                }}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom_two">
          <span class="size_data">村集体稳定增收光伏项目挂图作战进度表</span>
          <div class="stabilize">
            <div class="condition">项目推进情况</div>
            <div class="table_income" v-for="item in incomeData" :key="item.id"> <span class="table_td_size_2">{{
                item.name
            }}</span></div>
          </div>
          <div class="tableData1">
            <div class="keepOut1">
              <div class="table_content" v-for="item in photovoltaic" :key="item">
                <div class="table_td" v-for="list in item" :key="list.id">
                  <span v-if="list.name.length < 21" class="table_td_size">{{ list.name }}</span>
                  <img class="redFlagData" v-else :src="list.name" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Resize>
</template>

<script>
import Resize from '../util/Resize.vue'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      power: 9342.37, //功率
      dynamo: 30273.26,//发电量
      earnings: 8967.77, //收益
      addUp: 962.26,//累计

      energy: 2316.73, //节约
      reduce: 1921.19, //减少
      plant: 3912, //种植

      colorData: '',
      imgListData: '',
      isShow: false,
      placeData: '',
      location: [
        { id: 0, left: 172, top: 120, isShow: false, img: require('../assets/1data1.jpg'), place: '冯家镇东山后电站-5.41MW', color: '#ffb400' },
        { id: 1, left: 138, top: 230, isShow: false, img: require('../assets/jpg/jpg6.jpg'), place: '下洼镇前路电站-3.70MW', color: '#ffb400' },
        { id: 2, left: 108, top: 256, isShow: false, img: require('../assets/3data.jpg'), place: '下洼镇石家营电站-0.91MW', color: '#ffb400' },
        { id: 3, left: 116, top: 266, isShow: false, img: require('../assets/jpg/jpg7.jpg'), place: '下洼镇庄科电站-2.35MW', color: '#ffb400' },
        { id: 4, left: 20, top: 234, isShow: false, img: require('../assets/jpg/jpg8.jpg'), place: '古城镇魏王电站-1.59MW', color: '#ffb400' },
        { id: 5, left: 36, top: 260, isShow: false, img: require('../assets/jpg/jpg5.jpg'), place: '古城镇南关电站-4.38MW', color: '#ffb400' },
        { id: 6, left: 36, top: 270, isShow: false, img: require('../assets/7data.jpg'), place: '古城镇西关电站-1.47MW', color: '#ffb400' },
        { id: 7, left: 40, top: 296, isShow: false, img: require('../assets/8data.jpg'), place: '大高镇邢刘梁电站-4.15MW', color: '#ffb400' },
        { id: 8, left: 84, top: 316, isShow: false, img: require('../assets/9data.jpg'), place: '大高镇马家坊电站-0.91MW', color: '#ffb400' },
        { id: 9, left: 100, top: 306, isShow: false, img: require('../assets/10data.jpg'), place: '大高三小电站0.47MW', color: '#ffb400' },
        { id: 10, left: 108, top: 326, isShow: false, img: require('../assets/11data.jpg'), place: '黄升镇堤圈电站0.68MW', color: '#ffb400' },
        { id: 11, left: 140, top: 284, isShow: false, img: require('../assets/12data.jpg'), place: '黄升镇蔺皮电站1.61MW', color: '#ffb400' },
        { id: 12, left: 180, top: 326, isShow: false, img: require('../assets/13data.jpg'), place: '黄升镇南新电站-1.95MW', color: '#ffb400' },
        { id: 13, left: 166, top: 318, isShow: false, img: require('../assets/14data.jpg'), place: '黄升镇白家电站0.3MW', color: '#ffb400' },
        { id: 14, left: 156, top: 300, isShow: false, img: require('../assets/15data.jpg'), place: '黄升镇商家电站-0.3MW', color: '#ffb400' },
        { id: 15, left: 164, top: 290, isShow: false, img: require('../assets/16data.jpg'), place: '黄升镇王豸电站0.76MW', color: '#ffb400' },
        { id: 16, left: 220, top: 280, isShow: false, img: require('../assets/jpg/jpg3.jpg'), place: '富源苏王电站-0.59MW', color: '#ffb400' },
        { id: 17, left: 222, top: 266, isShow: false, img: require('../assets/jpg/jpg2.jpg'), place: '富源东胡电站1.78MW', color: '#ffb400' },
        { id: 18, left: 278, top: 268, isShow: false, img: require('../assets/19data.jpg'), place: '利国乡孙户电站-4.42MW', color: '#ffb400' },
        { id: 19, left: 250, top: 262, isShow: false, img: require('../assets/jpg/jpg4.jpg'), place: '富源街道张王电站-1.15MW', color: '#ffb400' },
        { id: 20, left: 286, top: 226, isShow: false, img: require('../assets/21data.jpg'), place: '利国乡畜光互补项目9.8MW', color: '#ffb400' },
        { id: 21, left: 254, top: 194, isShow: false, img: require('../assets/22data.jpg'), place: '富源西孔电站-1.78MW', color: '#ffb400' },
        { id: 22, left: 316, top: 146, isShow: false, img: require('../assets/23data.jpg'), place: '下河乡东李电站-5.92MW', color: '#ffb400' },
        { id: 23, left: 306, top: 146, isShow: false, img: require('../assets/24data.jpg'), place: '下河乡东李电站（试点）1.21MW', color: '#ffb400' },
        { id: 24, left: 230, top: 134, isShow: false, img: require('../assets/jpg/jgp1.jpg'), place: '滨海镇沿河村电站4.78MW', color: '#ffb400' },
      ],
      arrData: [
        { id: '0', name: '乡镇', },
        { id: '1', name: '电站序号', },
        { id: '2', name: '占地(亩)', },
        { id: '3', name: '建设规模 （兆瓦）', },
        { id: '4', name: '投资金额 （万元）', },
        { id: '5', name: '坐落位置', },
        { id: '6', name: '覆盖村庄', },
        { id: '7', name: '并网时间', },
        { id: '8', name: '4月发电收入(万元)', },
        { id: '9', name: '5月发电收入(万元)', },
        { id: '10', name: '6月发电收入(万元)', },
        { id: '11', name: '7月发电收入(万元)', },
        { id: '12', name: '8月发电收入(万元)', },
        { id: '13', name: '9月发电收入(万元)', },
        { id: '14', name: '10月发电收入(万元)', },
        { id: '15', name: '累计发电收入(万元)', },
        { id: '16', name: '到年底预计收入(万元)', },
      ],
      incomeData: [
        { id: '0', name: '乡镇 (街道)', }, 
        { id: '1', name: '电站序号', },
        { id: '2', name: '建设规模 （兆瓦）', },
        { id: '3', name: '投资金额 （万元）', },
        { id: '4', name: '坐落位置', },
        { id: '5', name: '土地迁占', },
        { id: '6', name: '土地整平', },
        { id: '7', name: '电力接入方案', },
        { id: '8', name: '发改备案', },
        { id: '9', name: '国土备案', },
        { id: '10', name: '打桩进度', },
        { id: '11', name: '铁件安装', },
        { id: '12', name: '光伏板安装', },
        { id: '13', name: '计划并网时间', },
        { id: '14', name: '年底预计收入（万元）', },
      ],


      // photovoltaic: [
      //   [{ id: '0', name: '下河乡', },
      //   { id: '1', name: '1', },
      //   { id: '2', name: '5.92', },
      //   { id: '3', name: '1961.76', },
      //   { id: '4', name: '东李村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: '正在安装', },
      //   { id: '12', name: '已订货', },
      //   { id: '13', name: '11月份', },],
      //   [{ id: '0', name: '古城镇', },
      //   { id: '1', name: '2', },
      //   { id: '2', name: '4.38', },
      //   { id: '3', name: '1467.10', },
      //   { id: '4', name: '南关村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: require('../assets/redFlag.png'), },
      //   { id: '12', name: require('../assets/redFlag.png'), },
      //   { id: '13', name: '已并网', },],
      //   [{ id: '0', name: '古城镇', },
      //   { id: '1', name: '3', },
      //   { id: '2', name: '1.59', },
      //   { id: '3', name: '531.48', },
      //   { id: '4', name: '魏王村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: '正在安装', },
      //   { id: '12', name: '已订货', },
      //   { id: '13', name: '8月份', },],
      //   [{ id: '0', name: '下洼镇', },
      //   { id: '1', name: '4', },
      //   { id: '2', name: '3.70', },
      //   { id: '3', name: '1239.17', },
      //   { id: '4', name: '前路村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: require('../assets/redFlag.png'), },
      //   { id: '12', name: '正在安装', },
      //   { id: '13', name: '8月份', },],
      //   [{ id: '0', name: '下洼镇', },
      //   { id: '1', name: '5', },
      //   { id: '2', name: '2.35', },
      //   { id: '3', name: '787.64', },
      //   { id: '4', name: '庄科村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: '正在安装', },
      //   { id: '12', name: '已订货', },
      //   { id: '13', name: '9月份', },],
      //   [{ id: '0', name: '黄升镇', },
      //   { id: '1', name: '6', },
      //   { id: '2', name: '1.61', },
      //   { id: '3', name: '540.53', },
      //   { id: '4', name: '蔺皮村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: '正在安装', },
      //   { id: '12', name: '已订货', },
      //   { id: '13', name: '12月份', },],
      //   [{ id: '0', name: '黄升镇', },
      //   { id: '1', name: '7', },
      //   { id: '2', name: '1.95', },
      //   { id: '3', name: '652.69', },
      //   { id: '4', name: '南辛村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: require('../assets/redFlag.png'), },
      //   { id: '12', name: '正在安装', },
      //   { id: '13', name: '12月份', },],
      //   [{ id: '0', name: '黄升镇', },
      //   { id: '1', name: '8', },
      //   { id: '2', name: '0.76', },
      //   { id: '3', name: '256.15', },
      //   { id: '4', name: '王豸村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: '正在安装', },
      //   { id: '12', name: '已订货', },
      //   { id: '13', name: '12月份', },],
      //   [{ id: '1', name: '9', },
      //   { id: '2', name: '0.86', },
      //   { id: '3', name: '286.55', },
      //   { id: '4', name: '堤圈村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: '准备打桩', },
      //   { id: '11', name: '已订货', },
      //   { id: '12', name: '已订货', },
      //   { id: '13', name: '12月份', },],
      //   [{ id: '0', name: '大高镇', },
      //   { id: '1', name: '10', },
      //   { id: '2', name: '4.15', },
      //   { id: '3', name: '1390.22', },
      //   { id: '4', name: '邢刘梁村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: require('../assets/redFlag.png'), },
      //   { id: '12', name: '正在安装', },
      //   { id: '13', name: '12月份', },],
      //   [{ id: '0', name: '大高镇', },
      //   { id: '1', name: '11', },
      //   { id: '2', name: '0.47', },
      //   { id: '3', name: '158.65', },
      //   { id: '4', name: '大高二小', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: require('../assets/redFlag.png'), },
      //   { id: '12', name: '正在安装', },
      //   { id: '13', name: '12月份', },],
      //   [{ id: '0', name: '滨海镇 泊头镇', },
      //   { id: '1', name: '12', },
      //   { id: '2', name: '4.78', },
      //   { id: '3', name: '1600.60', },
      //   { id: '4', name: '沿河村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: '正在安装', },
      //   { id: '12', name: '已订货', },
      //   { id: '13', name: '9月份', },],
      //   [{ id: '0', name: '富源街道', },
      //   { id: '1', name: '13', },
      //   { id: '2', name: '1.78', },
      //   { id: '3', name: '596.97', },
      //   { id: '4', name: '西孔村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: '准备打桩', },
      //   { id: '11', name: '已订货', },
      //   { id: '12', name: '已订货', },
      //   { id: '13', name: '9月份', },],
      //   [{ id: '0', name: '富源街道', },
      //   { id: '1', name: '14', },
      //   { id: '2', name: '1.15', },
      //   { id: '3', name: '384.59', },
      //   { id: '4', name: '张王村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: require('../assets/redFlag.png'), },
      //   { id: '12', name: require('../assets/redFlag.png'), },
      //   { id: '13', name: '8月份', },],
      //   [{ id: '0', name: '富源街道', },
      //   { id: '1', name: '15', },
      //   { id: '2', name: '1.78', },
      //   { id: '3', name: '594.80', },
      //   { id: '4', name: '东胡村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: '正在安装', },
      //   { id: '12', name: '已订货', },
      //   { id: '13', name: '10月份', },],
      //   [{ id: '0', name: '富源街道', },
      //   { id: '1', name: '16', },
      //   { id: '2', name: '0.59', },
      //   { id: '3', name: '196.46', },
      //   { id: '4', name: '苏王村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: require('../assets/redFlag.png'), },
      //   { id: '11', name: '正在安装', },
      //   { id: '12', name: '已订货', },
      //   { id: '13', name: '10月份', },],
      //   [{ id: '0', name: '冯家镇', },
      //   { id: '1', name: '17', },
      //   { id: '2', name: '5.41', },
      //   { id: '3', name: '1813.70', },
      //   { id: '4', name: '东山后村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: '准备打桩', },
      //   { id: '11', name: '已订货', },
      //   { id: '12', name: '已订货', },
      //   { id: '13', name: '11月份', },],
      //   [{ id: '0', name: '利国乡', },
      //   { id: '1', name: '18', },
      //   { id: '2', name: '4.42', },
      //   { id: '3', name: '1481.05', },
      //   { id: '4', name: '孙户村', },
      //   { id: '5', name: require('../assets/redFlag.png'), },
      //   { id: '6', name: require('../assets/redFlag.png'), },
      //   { id: '7', name: require('../assets/redFlag.png'), },
      //   { id: '8', name: require('../assets/redFlag.png'), },
      //   { id: '9', name: require('../assets/redFlag.png'), },
      //   { id: '10', name: '准备打桩', },
      //   { id: '11', name: '已订货', },
      //   { id: '12', name: '已订货', },
      //   { id: '13', name: '10月份', },],
      //   [{ id: '0', name: '合计', },
      //   { id: '1', name: '——', },
      //   { id: '2', name: '47.65', },
      //   { id: '3', name: '15960.11', },
      //   { id: '4', name: '——', },
      //   { id: '5', name: '——', },
      //   { id: '6', name: '——', },
      //   { id: '7', name: '——', },
      //   { id: '8', name: '——', },
      //   { id: '9', name: '——', },
      //   { id: '10', name: '——', },
      //   { id: '11', name: '——', },
      //   { id: '12', name: '——', },
      //   { id: '13', name: '——', },],
      // ],

      photovoltaic: [
        [{ id: '0', name: '下河乡', },
        { id: '1', name: '1', },
        { id: '2', name: '5.92', },
        { id: '3', name: '1961.76', },
        { id: '4', name: '东李村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name: require('../assets/redFlag.png'), },
        { id: '12', name: require('../assets/redFlag.png'), },
        { id: '13', name: '11月份', },
        { id: '14', name: '25', },],
        [{ id: '0', name: '古城镇', },
        { id: '1', name: '2', },
        { id: '2', name: '4.38', },
        { id: '3', name: '1467.10', },
        { id: '4', name: '南关村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name: require('../assets/redFlag.png'), },
        { id: '12', name: require('../assets/redFlag.png'), },
        { id: '13', name: '8月5日已并网', },
        { id: '14', name: '93', },],
        [{ id: '0', name: '古城镇', },
        { id: '1', name: '3', },
        { id: '2', name: '1.59', },
        { id: '3', name: '531.48', },
        { id: '4', name: '魏王村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name: require('../assets/redFlag.png'), },
        { id: '12', name: require('../assets/redFlag.png'), },
        { id: '13', name: '9月17已并网', },
        { id: '14', name: '28', },],
        [{ id: '0', name: '下洼镇', },
        { id: '1', name: '4', },
        { id: '2', name: '3.70', },
        { id: '3', name: '1239.17', },
        { id: '4', name: '前路村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'),  },
        { id: '11', name: require('../assets/redFlag.png'), },
        { id: '12', name: require('../assets/redFlag.png'), },
        { id: '13', name: '9月14已并网', },
        { id: '14', name: '56', },],
        [{ id: '0', name: '下洼镇', },
        { id: '1', name: '5', },
        { id: '2', name: '2.35', },
        { id: '3', name: '787.64', },
        { id: '4', name: '庄科村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'),  },
        { id: '11', name: require('../assets/redFlag.png'), },
        { id: '12', name: require('../assets/redFlag.png'), },
        { id: '13', name: '11月底', },
        { id: '14', name: '10', },],
        [{ id: '0', name: '黄升镇', },
        { id: '1', name: '6', },
        { id: '2', name: '1.61', },
        { id: '3', name: '540.53', },
        { id: '4', name: '蔺皮村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name: require('../assets/redFlag.png'), },
        { id: '12', name: '准备安装', },
        { id: '13', name: '12月份', },
        { id: '14', name: '0', },],
        [{ id: '0', name: '黄升镇', },
        { id: '1', name: '7', },
        { id: '2', name: '1.95', },
        { id: '3', name: '652.69', },
        { id: '4', name: '南辛村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name: require('../assets/redFlag.png'), },
        { id: '12', name: '准备安装', },
        { id: '13', name: '12月份', },
        { id: '14', name: '0', },],
        [{ id: '0', name: '黄升镇', },
        { id: '1', name: '8', },
        { id: '2', name: '0.76', },
        { id: '3', name: '256.15', },
        { id: '4', name: '王豸村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name:  require('../assets/redFlag.png'),},
        { id: '12', name: '准备安装', },
        { id: '13', name: '12月份', },
        { id: '14', name: '0', },],
        [{ id: '0', name: '黄升镇', },
        { id: '1', name: '9', },
        { id: '2', name: '0.86', },
        { id: '3', name: '286.55', },
        { id: '4', name: '堤圈村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: '准备打桩', },
        { id: '11', name:  '已订货',},
        { id: '12', name: '已订货', },
        { id: '13', name: '12月份', },
        { id: '14', name: '0', },],
        [{ id: '0', name: '大高镇', },
        { id: '1', name: '10', },
        { id: '2', name: '4.15', },
        { id: '3', name: '1390.22', },
        { id: '4', name: '邢刘梁村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name:  require('../assets/redFlag.png'),},
        { id: '12', name:  require('../assets/redFlag.png'), },
        { id: '13', name: '12月份', },
        { id: '14', name: '0', },],
        [{ id: '0', name: '大高镇', },
        { id: '1', name: '11', },
        { id: '2', name: '0.47', },
        { id: '3', name: '158.65', },
        { id: '4', name: '大高二小', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name:  require('../assets/redFlag.png'),},
        { id: '12', name: require('../assets/redFlag.png'), },
        { id: '13', name: '12月份', },
        { id: '14', name: '0', },],
        [{ id: '0', name: '滨海镇 泊头镇', },
        { id: '1', name: '12', },
        { id: '2', name: '4.78', },
        { id: '3', name: '1600.60', },
        { id: '4', name: '沿河村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name:  require('../assets/redFlag.png'),},
        { id: '12', name: require('../assets/redFlag.png'), },
        { id: '13', name: '11月3已并网', },
        { id: '14', name: '41', },],
        [{ id: '0', name: '富源街道', },
        { id: '1', name: '13', },
        { id: '2', name: '1.78', },
        { id: '3', name: '596.97', },
        { id: '4', name: '西孔村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name:  '正在安装',},
        { id: '12', name: '已订货', },
        { id: '13', name: '11月底', },
        { id: '14', name: '8', },],
        [{ id: '0', name: '富源街道', },
        { id: '1', name: '14', },
        { id: '2', name: '1.15', },
        { id: '3', name: '384.59', },
        { id: '4', name: '张王村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name: require('../assets/redFlag.png'), },
        { id: '12', name: require('../assets/redFlag.png'), },
        { id: '13', name: '9月7日已并网', },
        { id: '14', name: '20', },],
        [{ id: '0', name: '富源街道', },
        { id: '1', name: '15', },
        { id: '2', name: '1.78', },
        { id: '3', name: '594.80', },
        { id: '4', name: '东胡村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name: require('../assets/redFlag.png'), },
        { id: '12', name: require('../assets/redFlag.png'), },
        { id: '13', name: '10月1日已并网', },
        { id: '14', name: '27', },],
        [{ id: '0', name: '富源街道', },
        { id: '1', name: '16', },
        { id: '2', name: '0.59', },
        { id: '3', name: '196.46', },
        { id: '4', name: '苏王村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'), },
        { id: '11', name: require('../assets/redFlag.png'), },
        { id: '12', name: require('../assets/redFlag.png'), },
        { id: '13', name: '10月1日已并网', },
        { id: '14', name: '10', },],
        [{ id: '0', name: '冯家镇', },
        { id: '1', name: '17', },
        { id: '2', name: '5.41', },
        { id: '3', name: '1813.70', },
        { id: '4', name: '东山后村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name: require('../assets/redFlag.png'),  },
        { id: '11', name: require('../assets/redFlag.png'),  },
        { id: '12', name: '正在安装', },
        { id: '13', name: '11月底', },
        { id: '14', name: '25', },],
        [{ id: '0', name: '利国乡', },
        { id: '1', name: '18', },
        { id: '2', name: '4.42', },
        { id: '3', name: '1481.05', },
        { id: '4', name: '孙户村', },
        { id: '5', name: require('../assets/redFlag.png'), },
        { id: '6', name: require('../assets/redFlag.png'), },
        { id: '7', name: require('../assets/redFlag.png'), },
        { id: '8', name: require('../assets/redFlag.png'), },
        { id: '9', name: require('../assets/redFlag.png'), },
        { id: '10', name:require('../assets/redFlag.png'), },
        { id: '11', name: '正在安装', },
        { id: '12', name: '已订货', },
        { id: '13', name: '11月底', },
        { id: '14', name: '205', },],
        [{ id: '0', name: '合计', },
        { id: '1', name: '——', },
        { id: '2', name: '47.65', },
        { id: '3', name: '15960.11', },
        { id: '4', name: '——', },
        { id: '5', name: '——', },
        { id: '6', name: '——', },
        { id: '7', name: '——', },
        { id: '8', name: '——', },
        { id: '9', name: '——', },
        { id: '10', name: '——', },
        { id: '11', name: '——', },
        { id: '12', name: '——', },
        { id: '13', name: '——', },
        { id: '14', name: '363', },],
      ],





      contentData: [
        [{ id: '0', name: '下洼镇', },
        { id: '1', name: '2', },
        { id: '2', name: '15', },
        { id: '3', name: '0.90576', },
        { id: '4', name: '300', },
        { id: '5', name: '石家营村', },
        { id: '6', name: '辛庄村郭家庙村小郑村', },
        { id: '7', name: '——', },
        { id: '8', name: '——', },
        { id: '9', name: '5.14', },
        { id: '10', name: '4.16', },
        { id: '11', name: '3.96', },
        { id: '12', name: '5.3', },
        { id: '11', name: '4.02', },
        { id: '12', name: '22.58', },
        { id: '12', name: '27', },],
        [{ id: '0', name: '下河乡', },
        { id: '1', name: '3', },
        { id: '2', name: '27', },
        { id: '3', name: '1.20768', },
        { id: '4', name: '400', },
        { id: '5', name: '东李村', },
        { id: '6', name: '3个村', },
        { id: '7', name: '2022.5.18', },
        { id: '8', name: '——', },
        { id: '9', name: '3.63', },
        { id: '10', name: '6.54', },
        { id: '11', name: '6.23', },
        { id: '12', name: '5.99', },
        { id: '11', name: '6.3', },
        { id: '12', name: '4.86', },
        { id: '12', name: '29.92', },
        { id: '12', name: '36', },],
        [{ id: '0', name: '大高镇', },
        { id: '1', name: '4', },
        { id: '2', name: '24', },
        { id: '3', name: '0.90576', },
        { id: '4', name: '300', },
        { id: '5', name: '马家坊村', },
        { id: '6', name: '西黄村庙一村孔家村', },
        { id: '7', name: '2022.5.7', },
        { id: '8', name: '——', },
        { id: '9', name: '3.87', },
        { id: '10', name: '4.77', },
        { id: '11', name: '4.37', },
        { id: '12', name: '4.12', },
        { id: '11', name: '4.98', },
        { id: '12', name: '3.69', },
        { id: '12', name: '21.93', },
        { id: '12', name: '27', },],
        [{ id: '0', name: '黄升镇', },
        { id: '1', name: '5', },
        { id: '2', name: '17', },
        { id: '3', name: '0.68265', },
        { id: '4', name: '425.27', },
        { id: '5', name: '堤圈村', },
        { id: '6', name: '堤圈村', },
        { id: '7', name: '2022.5.30', },
        { id: '8', name: '——', },
        { id: '9', name: '0.19', },
        { id: '10', name: '3.57', },
        { id: '11', name: '3.15', },
        { id: '12', name: '2.73', },
        { id: '11', name: '3.85', },
        { id: '12', name: '2.93', },
        { id: '12', name: '16.23', },
        { id: '12', name: '20', },],
        [{ id: '0', name: '黄升镇', },
        { id: '1', name: '6', },
        { id: '2', name: '7', },
        { id: '3', name: '0.30192', },
        { id: '4', name: '425.27', },
        { id: '5', name: '商家村', },
        { id: '6', name: '白家村', },
        { id: '7', name: '2022.5.27', },
        { id: '8', name: '——', },
        { id: '9', name: '0.35', },
        { id: '10', name: '1.71', },
        { id: '11', name: '1.54', },
        { id: '12', name: '1.48', },
        { id: '11', name: '1.78', },
        { id: '12', name: '1.33', },
        { id: '12', name: '7.84', },
        { id: '12', name: '11', },],
        [{ id: '0', name: '黄升镇', },
        { id: '1', name: '7', },
        { id: '2', name: '7', },
        { id: '3', name: '0.30192', },
        { id: '4', name: '425.27', },
        { id: '5', name: '商家村', },
        { id: '6', name: '商家村', },
        { id: '7', name: '2022.5.27', },
        { id: '8', name: '——', },
        { id: '9', name: '0.36', },
        { id: '10', name: '1.7', },
        { id: '11', name: '1.44', },
        { id: '12', name: '1.44', },
        { id: '11', name: '1.78', },
        { id: '12', name: '1.33', },
        { id: '12', name: '7.69', },
        { id: '12', name: '11', },],
        [{ id: '0', name: '古城镇', },
        { id: '1', name: '8', },
        { id: '2', name: '25', },
        { id: '3', name: '1.466', },
        { id: '4', name: '483.65', },
        { id: '5', name: '西关村', },
        { id: '6', name: '8个村', },
        { id: '7', name: '2022.4.18', },
        { id: '8', name: '3.11', },
        { id: '9', name: '9.19', },
        { id: '10', name: '8.42', },
        { id: '11', name: '7.54', },
        { id: '12', name: '7.03', },
        { id: '11', name: '8.6', },
        { id: '12', name: '6.35', },
        { id: '12', name: '37.94', },
        { id: '12', name: '46', },],
        [{ id: '0', name: '合计', },
        { id: '1', name: '——', },
        { id: '2', name: '122', },
        { id: '3', name: '5.77169', },
        { id: '4', name: '1908.92', },
        { id: '5', name: '——', },
        { id: '6', name: '——', },
        { id: '7', name: '——', },
        { id: '8', name: '3.11', },
        { id: '9', name: '17.59', },
        { id: '10', name: '31.85', },
        { id: '11', name: '28.43', },
        { id: '12', name: '26.75', },
        { id: '10', name: '32.59', },
        { id: '11', name: '24.51', },
        { id: '12', name: '144.13', },
        { id: '12', name: '178', },],
      ],
      reject: '',
      time: '',
      setTime: '',
    }
  },
  watch: {
    colorData(newval, oldval) {
      console.log(newval, oldval, 'newval, oldval')
      // return oldval
      for (var i = 0; i <= this.location.length; i++) {
        if (oldval != '') {
          if (this.location[i].id == oldval) {
            console.log(oldval, 'this.location[i].id')
            this.location[i].color = '#ffb400'
          }
        }

      }
    }
  },
  components: {
    Resize
  },
  created() {
    this.time = setInterval(() => {
      console.log(this.addUp)
      this.power = this.power + 1.01
      this.dynamo = this.dynamo + 75
      this.earnings = this.dynamo * 0.3949
      this.addUp = this.addUp + 0.0075

      this.energy = this.addUp * 3.28
      this.reduce = this.addUp * 2.72
      this.plant = this.addUp * 5.54
    }, 60000)




    console.log(new Date(new Date().toDateString() + ' 8:00').getTime(), '900')
    var data = new Date(new Date().toDateString() + ' 10:00').getTime()  //获取时间戳
    var timestamp = ''
    this.setTime = setInterval(() => {
      timestamp = new Date().getTime()
      // console.log(timestamp,'timestamp')
      // console.log(data,'data')
      if (data <= timestamp) {
        this.power = 9342.37
        this.dynamo = 30273.26
        this.earnings = this.dynamo * 0.3949
        // console.log(data,'data')
        // clearInterval(this.time)
        data = new Date(new Date().toDateString() + ' 10:00').getTime() + 86400000
      }
    }, 1000)
  },
  mounted() {



    setTimeout(() => {
      this.drawLine();
      this.collectiveIncome();
      this.electricity();
      this.alarm()
    }, 100)
  },

  beforeDestroy() {
    console.log("beforeDestroy函数执行了");
    clearInterval(this.time)
    clearInterval(this.setTime)

  },
  methods: {
    gitSite(e) {
      console.log(this.colorData, 'this.rejectthis.rejectthis.rejectthis.reject')

      this.colorData = e.id
      this.imgListData = e.img
      this.isShow = !e.isShow
      this.placeData = e.place
      e.color = 'red'
      //  for(var i = 0; i<= this; i++){

      //  }
    },


    electricity() {
      var myChart = this.$echarts.init(document.getElementById('electricityType'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item'
        },
        grid: {
          left: "1%",
          // right:'20%'
        },
        legend: {
          top: '35%',
          left: '58%',
          textStyle: {
            color: '#ffffff',    //文字颜色（红绿蓝,透明度）-白色
            fontSize: 16
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            right: '20%',
            avoidLabelOverlap: false,

            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                textStyle: {
                  color: '#ffffff',    //文字颜色（红绿蓝,透明度）-白色
                  fontSize: 16
                },
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 6, name: '正常电站', itemStyle: { color: '#2CEA2C' } },
              { value: 1, name: '故障电站', itemStyle: { color: '#FF0000' } },
              { value: 0, name: '断连电站' },

            ]
          }
        ],
        media: [
          {
            query: { minAspectRatio: 1 },
            option: {
              series: [
                { center: ['40%', '50%'] },

              ]
            }
          },

        ]
      });
    },
    alarm() {
      var myChart = this.$echarts.init(document.getElementById('alarmApparatus'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item'
        },
        grid: {
          left: "1%",
          // right:'20%'
        },
        legend: {
          top: '30%',
          left: '58%',
          textStyle: {
            color: '#ffffff',    //文字颜色（红绿蓝,透明度）-白色
            fontSize: 16
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            right: '20%',
            avoidLabelOverlap: false,

            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                textStyle: {
                  color: '#ffffff',    //文字颜色（红绿蓝,透明度）-白色
                  fontSize: 16
                },
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 0, name: '紧急告警', itemStyle: { color: '#FF0000' } },
              { value: 3, name: '重要告警', itemStyle: { color: '#FE982B' } },
              { value: 0, name: '次要告警', itemStyle: { color: '#14DC5A' } },
              { value: 0, name: '提示告警', itemStyle: { color: '#01C4F7' } },


            ]
          }
        ],
        media: [
          {
            query: { minAspectRatio: 1 },
            option: {
              series: [
                { center: ['40%', '50%'] },

              ]
            }
          },

        ]
      });
    },

    drawLine() {
      var myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} (万元)'
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: '65%',
            right: '4%',
            data: [
              { value: 150, name: '运维支出及人员成本 150万元', itemStyle: { color: '#71C32E' } },
              { value: 1300, name: '项目净收益 1300万元', itemStyle: { color: '#385297' } },
              { value: 560, name: '各项税费 560万元', itemStyle: { color: '#37C2E0' } },
              { value: 500, name: '折旧费 500万元', itemStyle: { color: '#874FFA' } },
              { value: 90, name: '土地租赁费 100万元', itemStyle: { color: '#FF3079' } },

            ],
            label: {
              //文本普通样式
              normal: {
                textStyle: {
                  color: '#ffffff',    //文字颜色（红绿蓝,透明度）-白色
                  fontSize: 14
                }
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      });
    },
    collectiveIncome() {
      var myChart = this.$echarts.init(document.getElementById('histogram'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} (个)'
        },
        grid: {
          left: 70
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            //横坐标类目文字
            show: true,
            textStyle: {
              color: "#fff", //设置横坐标轴文字颜色
              fontSize: "14", //设置横坐标轴文字颜大小
            },
            interval: 0,
          },
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            //横坐标类目文字
            show: true,
            textStyle: {
              color: "#fff", //设置横坐标轴文字颜色
              fontSize: "14", //设置横坐标轴文字颜大小
            },
            interval: 0,
          },
          inverse: true,
          data: ['15万以上', '10~15万', '5~10万', '5万以下'],

        },
        series: [
          {
            // name:'个',
            type: 'bar',
            label: {
              show: true,
              position: 'right',
              // normal: {
              textStyle: {
                color: '#ffffff',    //文字颜色（红绿蓝,透明度）-白色
                fontSize: 16
              }
              // }
            },
            data: [{
              value: 193,
              itemStyle: {
                color: '#18D188'
              }
            }, {
              value: 183,
              itemStyle: {
                color: '#2C9EFF'
              }
            },
            {
              value: 62,
              itemStyle: {
                color: '#FFC22B'
              }
            },
            {
              value: 0,
              itemStyle: {
                color: '#FF2D54'
              }
            },]
          }
        ]
      });
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0
}

.hello {
  width: 1920px;
  height: 1080px;
  // background:aqua;
  background: url("../assets/bj.jpg") no-repeat center center/100% 100%;
  ;
}

.header {
  width: 100%;
  height: 64px;
  background: aquamarine;
  background: url("../assets/bigHeader.png") no-repeat center center/100% 100%;

}

.all {
  margin: 0 36px 0 36px;
}

.center {
  width: 100%;
  height: 538px;
  // background: bisque;
  margin: 18px 0;
  display: flex;
}

.left_one {
  width: 495px;
  height: 256px;
  margin-bottom: 12px;
  background: url("../assets/leftTop.png") no-repeat center center/100% 100%;
}

.left_two {
  width: 495px;
  height: 270px;
  background: url("../assets/leftCenter.png") no-repeat center center/100% 100%;
}

.middle {
  width: 820px;
  height: 518px;
  margin: 18px 0 0 16px;
  // text-align: center;
  background: url("../assets/centerTop.png") no-repeat center center/100% 100%;
  position: relative;
}

.right {
  margin-left: 18px;
}

.right_one {
  width: 496px;
  height: 256px;
  background: url("../assets/rightTop.png") no-repeat center center/100% 100%;
  margin-bottom: 12px;
}

.right_two {
  width: 496px;
  height: 270px;
  background: url("../assets/rightCenter.png") no-repeat center center/100% 100%;
}

.bottom {
  width: 100%;
  height: 392px;
  // background: aqua;
  display: flex;
}

.bottom_one {
  width: 912px;
  height: 392px;
  background: url("../assets/leftBottom.png") no-repeat center center/100% 100%;
}

.bottom_two {
  width: 912px;
  height: 392px;
  background: url("../assets/rightBottom.png") no-repeat center center/100% 100%;
  margin-left: 36px;
}

.size_data {
  font-size: 18px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #02d9fd;
  margin-top: 6px;
  display: inline-block;
}





.left_center_data {
  display: flex;
  margin-top: 52px;
}

.leftList {
  width: 145px;
  height: 161px;
  background: #65ffff;
  margin-left: 14px;
  // background: url("../assets/lcData1.png") no-repeat center center/100% 100%;

}

.leftList1 {
  background: url("../assets/lcData1.png") no-repeat center center/100% 100%;
}

.leftList2 {
  background: url("../assets/lcData2.png") no-repeat center center/100% 100%;
}

.leftList3 {
  background: url("../assets/lcData3.png") no-repeat center center/100% 100%;
}

.P_sty {
  margin-top: 50px;
  // margin: 0;
}

.number_S {
  font-size: 24px;
  color: #ffffff;
}

.label1 {
  font-size: 14px;
  color: #66F1C8;
  margin: 0;
}

.label2 {
  font-size: 14px;
  color: #FF5D96;
}

.label3 {
  font-size: 14px;
  color: #5874FF;
}

.font {
  font-size: 12px;
}

.powerStation {
  display: flex;
  // background: #FF5D96;
}

.powerStation_one {
  width: 250px;
  height: 238px;
  // background: #65ffff;
}

.powerStation_Two {
  width: 250px;
  height: 238px;
  // background: #65ffff;
}

.table_header {

  width: 890px;
  height: 82px;
  margin: 10px;
  background: #021950;
  display: flex;
}

.stabilize {
  width: 894px;
  height: 82px;
  margin: 10px;
  background: #021950;
  display: flex;
}

.table_content {
  width: 890px;
  height: 64px;
  margin: 0 10px;
  background: #0b1547;
  display: flex;
}

.table_td {
  width: 74px;
  height: 82px;
  // line-height: 82px;
  color: #02D9FD;
  font-size: 14px;
  text-align: center;
}

.table_income {
  width: 64px;
  height: 82px;
  color: #02D9FD;
  font-size: 14px;
  text-align: center;

}

.table_td_size {
  display: inline-block;
  margin-top: 20px;
}

.table_td_size_2 {
  display: inline-block;
  margin-top: 30px;
}

.tableData {
  width: 890px;
  height: 257px;
  overflow: hidden;
}

.tableData1 {
  width: 890px;
  height: 257px;
  overflow: hidden;
}

.keepOut {
  width: 890px;
  height: 257px;
  // overflow: hidden;
  animation: row 10s linear infinite;
}

.keepOut1 {
  width: 890px;
  height: 257px;
  // overflow: hidden;
  animation: row1 20s linear infinite;
}

.condition {
  width: 480px;
  height: 30px;
  border: 1px solid #141824;
  position: absolute;
  right: 100px;
  color: #02D9FD;
  font-size: 14px;
}

@keyframes row {
  0% {}

  100% {
    transform: translateY(-140%);
  }
}

@keyframes row1 {
  0% {}

  100% {
    transform: translateY(-400%);
  }
}

.middleData {
  width: 100%;
  height: 126px;
  // background: #FF5D96;
  display: flex;
}

.middleList {
  // width: 204px;
  // height: 126px;
  // background: #02D9FD;
  display: flex;
  text-align: center;
  margin: 30px 0 0 40px;
}

.font_data {
  font-size: 24px;
  color: #ffffff;
  margin-left: 4px;
}

.spanData {
  font-size: 12px;
  color: #ffffff;
}

.power {
  font-size: 14px;
  color: #01C4F7;
  text-align: left;
  margin-left: 4px;
}

.mapImg {
  width: 342px;
  height: 356px;
  background: #FF5D96;
  margin-left: 246px;
  background: url("../assets/mapData.png") no-repeat center center/100% 100%;
  position: relative;
}

.makData {
  width: 10px;
  height: 10px;
  background: #ffb400;
  // border: 3px solid rgba(255, 180, 0, 0.43);
  border-radius: 50%;
}

.imgSite {
  width: 254px;
  height: 154px;
  background: #fff;
  position: absolute;
  top: 120px;
  left: 40px;
}

.img_Sty {
  width: 242px;
  height: 136px;
}

.site_size {
  font-size: 14px;
  color: #333333;
}

.redFlagData {
  margin-top: 20px;
}

.strip {
  width: 162px;
  height: 10px;
  background: linear-gradient(#33aaf5 25%, #65ffff 100%);
  display: inline-block;
  margin: 0 2px 0 10px;
}

.strip1 {
  width: 154px;
  height: 10px;
  background: linear-gradient(#33aaf5 25%, #65ffff 100%);
  display: inline-block;
  margin: 0 4px 0 10px;
}

.strip2 {
  width: 145px;
  height: 10px;
  background: linear-gradient(#33aaf5 25%, #65ffff 100%);
  display: inline-block;
  margin: 0 4px 0 44px;
}

.strip3 {
  width: 135px;
  height: 10px;
  background: linear-gradient(#33aaf5 25%, #65ffff 100%);
  display: inline-block;
  margin: 0 4px 0 10px;
}

.strip4 {
  width: 130px;
  height: 10px;
  background: linear-gradient(#33aaf5 25%, #65ffff 100%);
  display: inline-block;
  margin: 0 4px 0 46px;
}

.ul_sty {
  list-style: none;
  margin: 30px 0 0 10px;
  text-align: left;
}

.ul_sty li {
  margin-bottom: 10px;
}

.address_sty {
  font-size: 18px;
  color: #fff;
}
</style>
